// extracted by mini-css-extract-plugin
export var linkhover = "mobileAppointments-module--linkhover--g-f20";
export var bottom = "mobileAppointments-module--bottom--NVsQk";
export var left = "mobileAppointments-module--left--yLr9M";
export var callout = "mobileAppointments-module--callout--OPJ7Q";
export var right = "mobileAppointments-module--right--x8O57";
export var link = "mobileAppointments-module--link--A5M4f";
export var top = "mobileAppointments-module--top--9crf7";
export var logolink = "mobileAppointments-module--logolink--nytSr";
export var lefttop = "mobileAppointments-module--lefttop--o8+Av";
export var logo = "mobileAppointments-module--logo--19WY8";
export var patientname = "mobileAppointments-module--patientname--gvUn5";
export var leftbottom = "mobileAppointments-module--leftbottom--oNo4i";
export var patientheading = "mobileAppointments-module--patientheading--iAIst";
export var request = "mobileAppointments-module--request--I33n+";
export var bg = "mobileAppointments-module--bg--UWQNF";
export var bgxl = "mobileAppointments-module--bgxl--wyyOU";
export var bgdesktop = "mobileAppointments-module--bgdesktop--nyBx7";
export var bgtablet = "mobileAppointments-module--bgtablet--jbh6W";
export var bgmobile = "mobileAppointments-module--bgmobile--ZT6sx";
export var heading = "mobileAppointments-module--heading--GMbyj";
export var blurb = "mobileAppointments-module--blurb--yljm1";
export var icon = "mobileAppointments-module--icon--fEbII";
export var insurance = "mobileAppointments-module--insurance--9xaI+";
export var insurancebottom = "mobileAppointments-module--insurancebottom--PfjE1";
export var insuranceimage = "mobileAppointments-module--insuranceimage---Zt3x";
export var patientcontent = "mobileAppointments-module--patientcontent--g0LIX";
export var contentleft = "mobileAppointments-module--contentleft--XXTKW";
export var linkcards = "mobileAppointments-module--linkcards--fAMH7";
export var linkcard = "mobileAppointments-module--linkcard--5X7uS";
export var title = "mobileAppointments-module--title--6ka6P";
export var cta = "mobileAppointments-module--cta--nG7JW";
export var ctacopy = "mobileAppointments-module--ctacopy--u8-2E";
export var iconyellow = "mobileAppointments-module--iconyellow--NqIFe";
export var icongray = "mobileAppointments-module--icongray--V2O6L";