import React, { useEffect } from 'react';
import * as styleSheet from "./index.module.scss";

const Iframe = () => {
  useEffect(() => {
		var greyfinchAppointmentWidgetIframe = document.querySelector('iframe[src*="https://appointments.greyfinch.com"]');    	
		window.addEventListener('message', function(event) {
			try {
				var data = JSON.parse(event.data);
				if (data.greyfinch) {
          //console.log(data);
          //console.log("Running: data.greyfinch eventlistener... ");
					if (typeof window.ga === 'function') {
						window.ga('send', 'event', data.type, data.event);
					} else if (typeof window.gtag === 'function') {
						window.gtag('event', data.event, { event_category: data.type });
					}
				}

				if (data.greyfinch && data.type === 'greyfinch/appointments/resize') {
          console.log(data.payload);
					greyfinchAppointmentWidgetIframe.style.height = data.payload.height + 'px';
				}
			} catch(error) {}
		});
	}, []);

  return <iframe className={styleSheet.greyfinchIframe} src="https://appointments.greyfinch.com/?division=108377&shrink=true" allow="geolocation" width="100%" title="Grey Finch Appointment App"></iframe>
};

export default Iframe;